import "./Thumb.css";

import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { photoInputsState } from "../recoil/atoms";
import { photoThumbnailUrl } from "../services/config";
import { Typography } from "@mui/material";
import CircularProgressWithContent from "./common/CircularProgressWithContent";

const noimageSrc = "/noimage_s.png";

const Thumb = (props) => {
  console.log("Thumb called!");

  const { obj } = props;
  const photoInputs = useRecoilValue(photoInputsState);
  const imgSrc = obj.thumbSPath
    ? `${photoThumbnailUrl}/${obj.thumbSPath}`
    : null;
  const [imgLoading, setImgLoading] = useState(true);
  
  if(imgLoading && !imgSrc && !props.obj.disabled) {
    // イメージのアップロード中でない場合（=通常の検索結果表示）で、イメージがないケースはnoimageを表示
    setImgLoading(false);
  }
  //console.log(photoInputs);

  const idx = photoInputs.findIndex((e) => e.input._id === obj._id);
  let style = null;
  if (idx !== -1 && !photoInputs[idx].equality) {
    console.log("change thumb border");
    style = { border: "4px #f78e31 solid" };
  }

  const handleLoadImage = () => {
    setImgLoading(false);
  };

  // 画像リンク切れのケースもnoimageを表示
  const handleErrorImage = (event) => {
    event.target.src = noimageSrc;
  };

  return (
    <div className="Thumb" style={style}>
      {imgLoading && (
        <CircularProgressWithContent>
          <Typography variant="caption" component="div" color="text.secondary">
            {imgSrc ? "読込" : "アップ"}
          </Typography>
        </CircularProgressWithContent>
      )}
      {imgSrc && (
        <img
          src={imgSrc}
          alt={props.obj._id}
          onLoad={handleLoadImage}
          onError={handleErrorImage}
          style={{ display: imgLoading ? "none" : "initial" }}
        />
      )}
      {!imgLoading && !imgSrc && (
        <img
          src={noimageSrc}
          alt="画像がありません"
        />
      )}
    </div>
  );
};
export default React.memo(Thumb);
