import './ConstIdDialog.css'

import { formatDate } from '../../utils/Utils'
import { constListData, sessionUserState, errorState } from '../../recoil/atoms'

import ProjectService from '../../services/projectService'

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FormControlLabel, Button, Checkbox, useMediaQuery } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { getReqErrorMessage } from "../../utils/errorUtil";

const ConstIdDialog = (props) => {
    const constMap = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    // const [text, setText] = useRecoilState(constIdState)
    // const [open, setOpen] = useState((text === null || text === ''))
    const [filter, setFilter] = useState(null)
    const [middle, setMiddle] = useState(true)
    const [favoriteMode, setFavoriteMode] = useState(false)
    const [favorite, setFavorite] = useState([])
    const setError = useSetRecoilState(errorState);// エラーメッセージ表示用
    const isSmall = useMediaQuery('(max-width:800px)') // スマホサイズ
    const userState = useRecoilValue(sessionUserState)

    useEffect(() => {
        if (props.open) {
            ProjectService.favoriteConstInfo()
                .then(
                    response => {
                        if (response.data) {
                            setFavoriteMode(true)
                            setFavorite(response.data.consts.map((row) => { return row.constId }))
                        }
                    },
                    error => {
                        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
                    }
                ).catch(error => {
                    setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
                })
        }
    }, [props.open, setError])

    let timeoutId = null
    return (
        <Dialog
            className="ConstIdDialog"
            //disableBackdropClick={!props.canCancel}
            disableEscapeKeyDown={!props.canCancel}
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={(event, reason) => {
                // disableBackdropClickがv5でなくなったための処理
                if (reason === 'backdropClick' && !props.canCancel) {
                    return;
                }
                if (props.onClose) {
                    props.onClose()
                }
              }}
            aria-labelledby="form-dialog-title"
            //scroll="paper"
        >
            <DialogTitle id="form-dialog-title">
              工事番号を入力
            </DialogTitle>
            <DialogContent >
                <div style={{color: '#f78e31'}} dangerouslySetInnerHTML={{ __html: props.message }} />
                <TextField
                  className="input-search"
                  //style={{ display: "block" }}
                  onChange={(event) => {
                    if (timeoutId) {
                        clearTimeout(timeoutId)
                    }

                    timeoutId = setTimeout(() => {
                        setFilter(event.target.value)
                    }, 500)
                  }}
                  defaultValue={filter}
                  size="small"
                  label="検索" />
                <FormControlLabel
                    className="input-process"
                    //style={{ display: "block" }}
                    control={
                        <Checkbox onChange={(event) => {
                            setMiddle(event.target.checked)
                        }} checked={middle} />
                    }
                    label={isSmall? "進行中" : "進行中の工事のみ表示"}
                />
                <FormControlLabel
                    className="input-favorite"
                    //style={{ display: "block", height: "30px" }}
                    control={
                        <Checkbox onChange={(event) => {
                            setFavoriteMode(event.target.checked)
                        }} checked={favoriteMode} />
                    }
                    label={isSmall? "最近" : "最近使った工番"}
                />
                <hr className="hr" style={{ marginTop: "10px", marginBottom: "10px" }} />
                <table className={"dataList"}>
                    <thead className={"scrollHead"}>
                        <tr>
                            {!isSmall &&
                                <th className={"choise"} style={{ textAlign: "center" }}>選択</th>
                            }
                            <th className={"constId"} style={{ textAlign: "center" }}>工事番号</th>
                            <th className={"name"} style={{ textAlign: "center" }}>工事名</th>
                            <th className={"city"} style={{ textAlign: "center" }}>住所</th>
                            <th className={"term"} style={{ textAlign: "center" }}>工期</th>
                            <th className={"process"} style={{ textAlign: "center" }}>状況区分</th>
                        </tr>
                    </thead>
                    <tbody className={"scrollBody"} >
                        {
                            Array.from(constMap.values()).filter(row => {
                                // 検索フィルター
                                return (
                                    (
                                        (!middle) || (middle && row.process === "工事進行中")
                                    )
                                    && (
                                        !filter
                                        || (row.constId && row.constId.indexOf(filter.toUpperCase(), 0) >= 0)
                                        || (row.name && row.name.indexOf(filter, 0) >= 0)
                                        || (row.city && row.city.indexOf(filter, 0) >= 0)
                                    )
                                    && (
                                        !userState.personalSetting // 設定自体が未登録のケース
                                        || (userState.personalSetting.area === "大阪" && row.constId.startsWith("O"))
                                        || (userState.personalSetting.area === "東京" && row.constId.startsWith("T"))
                                        || userState.personalSetting.area === "指定無し"
                                        || !userState.personalSetting.area
                                    )
                                )
                            }).filter((row) => {
                                // 最近使った工番のフィルター
                                return (!favoriteMode || (favorite && favorite.includes(row.constId)))
                            }).map(row => (
                                <tr key={row.constId}
                                    onClick={() => {
                                        if (isSmall && props.onChoise) {
                                            props.onChoise(row)
                                        }
                                    }}
                                >
                                    {!isSmall &&
                                        <td className={"choise"}>
                                            <Button variant="contained" size="small"
                                                onClick={() => {
                                                    if (props.onChoise) {
                                                        props.onChoise(row)
                                                    }
                                                }} color="primary">
                                                選択
                                            </Button>
                                        </td>
                                    }
                                    <td className={"constId"}>
                                        <div>{row.constId}</div>
                                    </td>
                                    <td className={"name"}>
                                        <div>{row.name}</div>
                                    </td>
                                    <td className={"city"}>
                                        <div>{row.city}</div>
                                    </td>
                                    <td className={"term"}>
                                        <div>{formatDate(row.termFrom)}〜{formatDate(row.termTo)}</div>
                                    </td>
                                    <td className={"process"}>
                                        <div>{row.process}</div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </DialogContent>
            {
                (props.canCancel) ? (
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose()
                                }
                            }} >
                            キャンセル
                        </Button>
                    </DialogActions>
                ) : (
                    <React.Fragment />
                )
            }
        </Dialog>
    )

}

export default ConstIdDialog