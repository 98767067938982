import {baseUrl} from './config';

const login = async () => {
  const token = localStorage.getItem('token');
  if(token) {
    /**
     * アクセストークンによるログイン
     * iOSのPWAで現状パスワードの自動入力が効かない(インストールされているデバイス管理アプによる制限設定)ため、
     * ローカルストレージにアクセストークンを保持し、このトークンで認証可能とするように対応した。
     * appleのITP制限により、現状Safariのローカルストレージの保持期間は「7日」とされている。
     * 
    */
    const action = baseUrl + `/login/token?RelayState=` + encodeURIComponent(window.location.href);
    const data = {token: token};
    execPost(action, data);
    // post送信->リダイレクトが完了するであろうタイミングまで間があり、この間にresponse処理に進んでしまうのを防ぐために待機する。
    await new Promise(s => setTimeout(s, 60000));
  } else {
    // SAMLではAjaxは使用できないためリダイレクトさせる必要がある
    // RelayStateパラメータ：ログイン後のリダイレクト先（必須）。クライアント側の現在のURLにしておく
    window.location.href = baseUrl + `/login?RelayState=` + encodeURIComponent(window.location.href);
  }

  return false;//axiosのエラーハンドリングをキャンセルする
};

const logout = () => {
  // アクセストークンを削除
  localStorage.removeItem('token');

  // SAMLではAjaxは使用できないためリダイレクトさせる必要がある
  // RelayStateパラメータ：ログアウト後のリダイレクト先（必須）。クライアント側の現在のURLにしておく。
  window.location.href = baseUrl + `/logout?RelayState=` + encodeURIComponent(window.location.href);
};

const execPost = (action, data) => {
  // フォームの生成
  let form = document.createElement("form");
  form.setAttribute("action", action);
  form.setAttribute("method", "post");
  form.style.display = "none";
  document.body.appendChild(form);// submitするためにフォームはドキュメント内になければいけない
  
  if (data !== undefined) {
   for (var paramName in data) {
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', paramName);
    input.setAttribute('value', data[paramName]);
    form.appendChild(input);
   }
  }
  form.submit();
 }

const exports = {
  login,
  logout,
}; 

export default exports;