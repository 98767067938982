import "./App.css";
import { RecoilRoot, useRecoilState } from 'recoil';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as colors from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "./components/Layout";
import Error from './components/common/Error'
import { errorState } from './recoil/atoms'

const theme = createTheme({
  palette: {
    mode: "dark", // ダークモード
    primary: {
      main: colors.green[500],
    },
    secondary: {
      //main: colors.yellow[900],
      main: '#f78e31',
    },
    typography: {
      htmlFontSize: 12,
      fontSize: 12,
      button: {
        textTransform: "none", // ボタンのラベルが小文字から大文字に変換されてしまうのを防ぐ
      },
    },
  },
  components: {
    // セレクトボックス
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
    },
    // input
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: "5px !important",
          paddingBottom: "5px !important",
        }
      },
    },
    // button
    MuiButton: {
      // variant: 'contained',
      defaultProps: {
        // Some CSS
        variant: 'contained',
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <div className="App">
          <Layout />
          <MessageHandler />
          <div className="SW-update-dialog"></div>
        </div>
      </RecoilRoot>
    </ThemeProvider>
  );
}

const MessageHandler = () => {
  console.log("MessageHandler called");
  const [error, setError] = useRecoilState(errorState) // エラーメッセージ表示用
  //const [message, setMessage] = useRecoilState(messageState) // メッセージ表示用
  return (
    <div>
      {/* <Message title={message.title} msg={message.msg} open={message.open} onOk={() => { setMessage({ msg: null, open: false }) }} /> */}
      <Error title={error.title} msg={error.msg} open={error.open} onOk={() => { setError({ msg: null, open: false }) }} />
    </div>
  )
}


export default App;
