import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState, useRecoilCallback } from "recoil";
import { sessionUserState, searchConditionState, photosState, activeIndexState, photoInputsState, cameraSettingState, errorState } from "../recoil/atoms";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Thumbs, Keyboard } from "swiper/core";
import Photo from "./Photo";
import Thumb from "./Thumb";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MeService from "../services/meService";
import CameraService from "../services/cameraService";
import { formatIso } from "../utils/dateUtil";
import { formatPhoto } from "../utils/photoUtil";
import { getReqErrorMessage } from "../utils/errorUtil";


import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import "./Layout.css";

SwiperCore.use([Thumbs, Keyboard]); // install Swiper's Thumbs component

const Layout = () => {
  console.log("Layout called");
  // store thumbs swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  const setSessionUser = useSetRecoilState(sessionUserState);
  const setCameraSetting = useSetRecoilState(cameraSettingState);
  const searchCondition = useRecoilValue(searchConditionState);
  const [photos, setPhotos] = useRecoilState(photosState);
  const setActiveIndex = useSetRecoilState(activeIndexState);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const getActiveIndex = useRecoilCallback(({snapshot}) => async () => {
    const activeIndex = await snapshot.getPromise(activeIndexState);
    return activeIndex;
  });
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用

  const handleChangeActiveIndex = (swiper) => {
    //console.log("handleChangeActiveIndex called");
    //console.log(swiper.slideTo(0));// 指定したインデックスにスライドを移動させることができる
    //console.log(swiper.slides.length);// スライドの総数
    //console.log(swiper);

    setActiveIndex(swiper.activeIndex);
  };

  // スライダーからスライドが追加・削除された時に発動
  const handleSlideTo = (swiper) => {
    console.log("handleSlideTo called");
    //console.log("swiper.activeIndex:"+swiper.activeIndex);
    getActiveIndex().then((currentIndex) => {
      //console.log("currentIndex:"+currentIndex);
      // スライドさせる
      swiper.slideTo(currentIndex);
    });
  };

  // セッション維持のため定期的にリクエストを投げ続ける
  useEffect(() => {
    console.log("Layout useEffect1 called");
    
    function me() {
      Promise.all([
        MeService.me(),
        MeService.findPersonalSetting(), // 個人設定
        MeService.getToken(),
      ])
        .then(
          (response) => {
            console.log("meService me, findPersonalSetting, getToken");
            //console.log(response);
            let userData = response[0].data;
            let personalSetting = response[1].data;
  
            if (personalSetting) {
              userData.personalSetting = personalSetting;
            }
  
            // state更新
            setSessionUser(userData);

            // ローカルストレージにアクセストークンを保持する
            let token = response[2].data;
            localStorage.setItem('token', token);
          },
          (error) => {
            setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
          }
        );
    }
    me();
    MeService.getCameraSetting().then(
      (response) => {
        console.log("meService getCameraSetting");
        //console.log(response.data);
        // state更新
        const cameraSetting = response.data;
        if(cameraSetting !== null) {
          setCameraSetting(cameraSetting);
        }
      },
      (error) => {
        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
      }
    );
    
    let rotationInterval = setInterval(me, 600000);// 10分間隔でリクエスト

    // アンマウント時に解除(メモリリーク対策)
    return ()=> {
      clearInterval(rotationInterval);
    }
  },[setSessionUser, setCameraSetting, setError]);

  // 検索条件が変更したら写真の検索処理を行う
  useEffect(() => {
    console.log("Layout useEffect2 called");

    const constId = searchCondition.constId;
    const day = searchCondition.day;

    // 検索条件
    const query = `{
      constId: "${constId}",
      day: {
        $gte: ISODate("${formatIso(day, "start")}"),
        $lte: ISODate("${formatIso(day, "end")}")
      }
    }`;
    const paging = {
      limit: 10000,
      page: 1,
      sort: [
        { field: "day", direction: "asc" },
        { field: "_id", direction: "asc" },
      ],
    };
    const fields = [];

    CameraService.findAll(query, paging, fields).then(
      (response) => {
        console.log("findAll");
        //console.log(response.data);
        // state更新
        const initialObjects = response.data.objects.map((obj) => {
          return formatPhoto(obj);
        });
        //console.log(initialObjects);
        setPhotos(initialObjects);
        // 関連のあるstateも初期化が必要
        setActiveIndex(0);
        setPhotoInputs([]);
      },
      (error) => {
        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
      }
    );
  }, [searchCondition, setPhotos, setActiveIndex, setPhotoInputs, setError]);

  return (
    <div className="container">
      <header className="header">
        <Header />
      </header>
      <main className="main">
        {/* Main Swiper -> pass thumbs swiper instance */}
        <Swiper
          thumbs={{ swiper: thumbsSwiper }}
 
          //スライドの状態変化イベント　 
          //onSlideChange={handleChangeActiveIndex}// 発生タイミングが早い。日付検索でアクティブなインデックスが変わらないケースがあり利用しない。
          onSlideChangeTransitionStart={handleChangeActiveIndex}
          //onSlideChangeTransitionEnd={handleChangeActiveIndex}// 発生タイミングが遅い。写真情報の表示の遅延が気になるため利用しない。
          
          //calculateHeight={true}
          //autoHeight={true}
          //spaceBetween={100}
          //preventClicks={false}
          //preventClicksPropagation={false}
          //preventInteractionOnTransition={false}
          //allowTouchMove={false} // falseにするとセレクトが動くが、スライドは動かない
          touchStartPreventDefault={false} // falseにすると中のmuiのselectが表示できる
          observer={true} // スライドが追加・削除されたのを監視する
          onObserverUpdate={handleSlideTo} // 上記有効の場合にスライドが追加・削除された時に発生するイベント
          speed={200}
          keyboard={true}
          //touchRatio={0.0001} // 感度を低めに調整する　デフォルト:1 数字が大きければ少しのスワイプで次に行く -> 効果なし
          //shortSwipes={false} // 少しのスワイプ禁止-> 逆にやりづらくなった
          threshold={10} //　感度を低めに調整する スワイプ時に次のスライドへ遷移させる場合の、最低限のスワイプ距離。デフォルト:0 指定の数字よりも距離が短い場合は、スライダーは遷移しない。
        >
          {photos.map((obj, idx) => {
            return (
              <SwiperSlide key={obj._id}>
                <Photo obj={obj} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </main>
      <aside className="aside">
        <Sidebar />
      </aside>
      <footer className="footer">
        {/* Thumbs Swiper -> store swiper instance */}
        {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */}
        <Swiper
          onSwiper={setThumbsSwiper}
          watchSlidesVisibility
          watchSlidesProgress
          spaceBetween={10}
          slidesPerView={10}
          freeMode={true}
          //autoHeight={true}
          //height={200}
          //centeredSlides={true}
          //spaceBetween={5}
        >
          {photos.map((obj, idx) => {
            return (
              <SwiperSlide key={obj._id}>
                <Thumb obj={obj} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </footer>
    </div>
  );
};

export default Layout;
