
// axiosのerrorオブジェクトから適切なエラーメッセージを返す
const getReqErrorMessage = (error) => {
  let errorMessage = "";
  
  if (error.response) {
    // このリクエストはステータスコードとともに作成されます
    // 2xx系以外の時にエラーが発生します
    if(error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = error.message;
    }
  } else if (error.request) {
    // このリクエストはレスポンスが返ってこない時に作成されます。
    // `error.request`はXMLHttpRequest のインスタンスです。
    errorMessage = error.request.status + " " + error.request.statusText;
  } else {
    //それ以外で何か以上が起こった時
    errorMessage = error.message;
  }
  return errorMessage;
};

export { getReqErrorMessage };
