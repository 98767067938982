import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { sessionUserState } from "../../recoil/atoms";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Logout from "./Logout";

const UserInfo = () => {
  const [open, setOpen] = useState(false);
  const sessionUser = useRecoilValue(sessionUserState);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (event) => {
  //   setSetting({ ...setting, [event.target.name]: event.target.checked });
  // };

  return (
    <div>
      <IconButton
        className="const-icon-button"
        color="primary"
        aria-label="userInfo"
        component="div"
        onClick={handleOpen}
      >
        <PersonOutlineOutlinedIcon fontSize="large" />
        <div className="const-icon-label">個人情報</div>
      </IconButton>
      <Dialog
        keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
        //fullScreen
      >
        <DialogTitle>個人情報</DialogTitle>
        <DialogContent>
          ユーザー名：{sessionUser.userName}
          <Logout />
          {/* <div>test</div>
          <div>t:{localStorage.getItem('token')}</div>
          <div>d:{localStorage.getItem('date')}</div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UserInfo;
