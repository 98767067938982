import axios from "axios";
import LoginService from "./loginService";

const baseUrl = window.location.origin + "/ConstManage";
//const baseUrl = "https://trievo.home" + "/ConstManage";// テスト用
//const baseUrl = "https://const.san-ki.jp" + "/ConstManage";// テスト用

const photoImageUrl = baseUrl + "/v1/camera/image";
const photoThumbnailUrl = baseUrl + "/v1/camera/thumb";

const http = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json",
    //"X-Requested-With" : "XMLHttpRequest",
  },
  withCredentials: true, // cookieを送信する
});

export {http, baseUrl, photoImageUrl, photoThumbnailUrl};

// 401 unauthorized httpステータスのカウント。認証処理によるリダイレクトで0にリセットされる。
let status401Count = 0;
// axios共通エラーハンドリング
http.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log("axios.interceptors.response called");
  return response;
}, async function (error) {
  console.log("axios.interceptors.error called.  " + error);
  if(status401Count > 0) {
    // 非同期処理中で、認証処理を1アクションに抑制するため、他があれば待機させる
    await new Promise(s => setTimeout(s, 60000));
  } 
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  switch (error.response?.status) {
    case 401:
      //console.log("axios.interceptors.error 401");
      status401Count++;
      //console.log("response401Count:"+ status401Count);
      // 未ログイン状態の場合、認証をする
      return LoginService.login();
    default:
      //console.log("axios.interceptors.error default");
      return Promise.reject(error);
 }
  
});

