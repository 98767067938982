import { selector } from 'recoil';
import { photosState, activeIndexState } from './atoms'

// 現在アクティブな状態にある写真情報の_id
export const activeIdSelector = selector({
  key: 'activeId',
  get: ({get}) => {
    // swiperのアクティブindexから_idを取得する
    const index = get(activeIndexState);
    const photos = get(photosState);
    const target = photos[index];
    if(!target) {
      return null;
    }
    return target._id;
  },
});