import { http } from "./config"

const requestPath = "/v1/projects"

// 工事案件一覧を取得する
const findAll = (query, paging, fields) => {
  let params = [];

  if (fields !== null && fields.length) {
    params.push("fields=" + fields.join(','))
  }
  if (paging) {
    if (paging.limit) {
      params.push(`limit=${paging.limit}`)
      const offset = paging.limit * (paging.page - 1)
      params.push(`offset=${offset}`)
    }

    paging.sort.forEach((s) => {
      params.push(`sort=${s.field},${s.direction}`)
    })
  }
  if (query) {
    params.push("query=" + encodeURIComponent(query))
  }

  return http.get(`${requestPath}/${params.length ? "?" + params.join("&") : ""}`)
};

// 最近使った工番
const favoriteConstInfo = () => {
  return http.get(`${requestPath}/favorite`)
}

// 指定した工事で利用できる工種情報を取得する
const constInfo = (constId) => {
  return http.get(`${requestPath}/constinfo/${constId}`)
}

const exports = {
  findAll,
  favoriteConstInfo,
  constInfo,
};

export default exports
