import React from "react";
import { useRecoilValue } from "recoil";
import { photosState, activeIndexState } from "../recoil/atoms";

const Counter = (props) => {
  console.log("Counter called!");
  const {className} = props;

  const photos = useRecoilValue(photosState);
  const activeIndex = useRecoilValue(activeIndexState);

  const total = photos.length;
  const current = total > 0 ? activeIndex + 1 : 0;

  // // TODO ローカルストレージの保持期間テスト用　後で消す
  // //  test localstorageにログイン情報を保持
  // const handleClick = () => {
  //   //localStorage.setItem('token', sessionUser.userId);
  //   localStorage.setItem('date', new Date());
  // };

  return (
    <div className={className}>
    {/* <div className={className} onClick={handleClick}> */}
      {current}/{total}
    </div>
  );
};
export default React.memo(Counter);
