import './Sidebar.css';

import React from "react";
import StdCamera from './actions/StdCamera';
import ImageUpload from './actions/ImageUpload';
import PhotoSearch from './actions/PhotoSearch';
import Setting from './actions/Setting';
import UserInfo from './actions/UserInfo';

const Sidebar = () => {

  /**
   * TODO ボタンが多くなった場合に縦スクロールされるように考慮する
   * 現状、スクロールが時々フリーズする...。iosの問題か?
   * css scroll freeze ios
   */

  return (
    <div className="Sidebar">
      <UserInfo />
      <PhotoSearch />
      <StdCamera />
      <ImageUpload />
      <Setting />
    </div>
  );
};
export default Sidebar;
