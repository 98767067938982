import "./ImageUpload.css";

import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  photosState,
  photoInputsState,
  cameraSettingState,
  activeIndexState,
  searchConditionState,
  errorState,
} from "../../recoil/atoms";
import { IconButton } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
//import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CameraService from "../../services/cameraService";
import { formatIso } from "../../utils/dateUtil";
import { formatPhoto, copyPhoto, isAllowedExt } from "../../utils/photoUtil";
import produce from "immer";
import { getReqErrorMessage } from "../../utils/errorUtil";
// import EXIF from "exif-js";
// import { saveAs } from "file-saver";

const StdCamera = () => {
  const [photos, setPhotos] = useRecoilState(photosState);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const cameraSetting = useRecoilValue(cameraSettingState);
  const setActiveIndex = useSetRecoilState(activeIndexState);
  const searchCondition = useRecoilValue(searchConditionState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用

  const handleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // iosではexif情報(撮影日や位置情報)が取得できない...
      // EXIF.getData(file, function() {
      //   var exifData = EXIF.pretty(this);
      //   if (exifData) {
      //     //console.log(exifData);
      //     //console.log(EXIF.getTag(this, "Orientation"));
      //     alert(exifData);
      //     alert(EXIF.getTag(this, "Orientation"));
      //   } else {
      //     //console.log("No EXIF data found in image '" + file.name + "'.");
      //     alert("No EXIF data found in image '" + file.name + "'.");
      //   }
      // });

      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   console.log(e.target.result);
      //   //const fileName = "stdtest.jpg";
      //   //saveAs(e.target.result, fileName);
      //   //alert(file);

      //   event.target.value = null;// アップロードファイルをクリア
      // }
      // reader.readAsDataURL(file)

      // 写真情報
      const now = new Date();
      let photoInput = {
        constId: searchCondition.constId,
        dateTimeOriginal: formatIso(now),
        day: formatIso(now, "start"),
      };

      // 複写
      const total = photos.length;
      if (total > 0) {
        // 最後尾の写真情報から複写する
        const lastIndex = total - 1;
        const copied = copyPhoto(photos[lastIndex], cameraSetting);

        photoInput = {
          ...photoInput,
          ...copied,
        };
      }
      console.log(photoInput);

      // パフォーマンスのためまず写真情報のみアップロード
      CameraService.createPhoto(photoInput).then(
        (response) => {
          //console.log(response.data);
          const result = response.data;
          const createdPhoto = {
            ...formatPhoto(result),
            disabled: true, // 編集無効フラグ。写真イメージのアップロードが完了するまでユーザーによる編集・更新操作をさせない目的で使用
          };
          
          // state変更
          setPhotos((objects) => {
            const updatedObjects = produce(objects, (draft) => {
              draft.push(createdPhoto);
            });

            return updatedObjects;
          });

          // 登録した写真にスライド
          setActiveIndex(total);

          if(!isAllowedExt(file.name)) {
            console.log("file.name:" + file.name);
            setError({ msg: "写真はJPEGファイルである必要があります。\n元のファイル名:"+file.name, open: true, title: "エラーが発生しました。" })
            // 写真イメージのアップロードで失敗した場合、削除できるようにする
            setPhotoError(result._id);

            return;// 終了
          }

          // 次に写真イメージをアップロード
          CameraService.updateImage(result._id, file).then(
            (response) => {
              //console.log(response.data);
              const updatedPhoto = formatPhoto(response.data);
              // state変更
              setPhotos((objects) => {
                const updatedObjects = produce(objects, (draft) => {
                  const idx = draft.findIndex(
                    (obj) => obj._id === result._id
                  );
                  if (idx !== -1) {
                    draft[idx] = updatedPhoto;
                  } else {
                    console.log("not found");
                  }
                });

                return updatedObjects;
              });

              setPhotoInputs((objects) => {
                const updatedObjects = produce(objects, (draft) => {
                  const idx = draft.findIndex(
                    (obj) => obj.input._id === result._id
                  );
                  if (idx !== -1) {
                    draft[idx] = { equality: true, input: updatedPhoto };
                  } else {
                    console.log("not found");
                  }
                });

                return updatedObjects;
              });
            },
            (error) => {
              console.log("photo upload fail");
              setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })

              // 写真イメージのアップロードで失敗した場合、削除できるようにする
              setPhotoError(result._id);
            }
          );
        },
        (error) => {
          setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
        }
      );
      
      event.target.value = null; // アップロードファイルをクリア
    }
  };

  const setPhotoError = (photoId) => {
    // 写真イメージのアップロードで失敗した場合、削除できるようにする
    setPhotos((objects) => {
      const updatedObjects = produce(objects, (draft) => {
        const idx = draft.findIndex(
          (obj) => obj._id === photoId
        );
        if (idx !== -1) {
          draft[idx].disabled = false;// 削除できるように編集無効フラグをoffにする
        } else {
          console.log("not found");
        }
      });

      return updatedObjects;
    });
  };

  return (
    <div className="ImageUpload">
      <input
        id="upload-camera"
        type="file"
        accept="image/jpeg"
        //accept=".heif, .heic"
        capture="camera"
        onChange={handleChange}
      />
      <label htmlFor="upload-camera">
        <IconButton
          className="const-icon-button"
          color="primary"
          aria-label="upload camera"
          component="div"
        >
          <CameraAltOutlinedIcon fontSize="large" />
          <div className="const-icon-label">撮影</div>
        </IconButton>
      </label>
    </div>
  );
};

export default StdCamera;
