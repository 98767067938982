import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { searchConditionState, photoInputsState } from "../../recoil/atoms";
import {
  //TextField,
  IconButton,
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Calendar from "../common/Calendar";

const PhotoSearch = () => {
  console.log("PhotoSearch called");
  const [open, setOpen] = useState(false);
  const [searchCondition, setSearchConditionState] =
    useRecoilState(searchConditionState);
  const [day, setDay] = useState(searchCondition.day);
  const photoInputs = useRecoilValue(photoInputsState);

  // メッセージ
  let message = "";
  const idx = photoInputs.findIndex((e) => e.equality === false);
  if (idx !== -1) {
    // 未更新のデータがある場合は確認メッセージを出す
    message =
      "編集中のデータが存在します。<br/>編集内容は消えてしまいますが宜しいですか？";
  }

  useEffect(() => {
    console.log("PhotoSearch useEffect called");
    // 検索条件の日付が変更されたらstateを変更
    setDay(searchCondition.day);
  }, [searchCondition.day]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDayChange = (changedDay) => {
    setDay(changedDay);
  };

  const handleOk = (event) => {
    // stateを変更
    console.log("PhotoSearch ok");
    
    // 同じオブジェクトだとsetしてもstateは変更されない。
    // 検索条件が同じでも必ず検索させるために、新しいオブジェクトとしてセットする。
    setSearchConditionState((prev) => {
      return {
        ...prev,
        day: day,
      };
    });
    // ダイアログを閉じる
    setOpen(false);
  };

  return (
    <>
      <IconButton
        className="const-icon-button"
        onClick={handleOpen}
        aria-label="PhotoSearch"
        color="primary"
        component="div"
      >
        <SearchOutlinedIcon fontSize="large" />
        <div className="const-icon-label">検索</div>
      </IconButton>
      <Dialog
        keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
        //fullScreen
        PaperProps={{
          style: {
            maxHeight: "100%",
          }
         }}
      >
        {/* <DialogTitle>検索</DialogTitle> */}
        <DialogContent>
          <Calendar
            value={day}
            onChange={handleDayChange}
          />
          <div style={{color: '#f78e31'}} dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleOk}>検索</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PhotoSearch;
