import React from 'react'

// export const getNow = () => {
//     var dt = new Date()
//     var y = dt.getFullYear()
//     var m = ("00" + (dt.getMonth() + 1)).slice(-2)
//     var d = ("00" + dt.getDate()).slice(-2)
//     var result = y + "-" + m + "-" + d
//     return result
// }

// export const getToday = () => {
//     let now = new Date();
//     return new Date(now.getFullYear(), now.getMonth(), now.getDate());
// }

export const formatDate = (dt) => {
    if (!dt) {
        return dt
    }
    if (typeof (dt) === 'string') {
        dt = new Date(dt)
    }
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    let idx = dt.getDay()
    let w = '日月火水木金土'[idx]
    return (
        <span>
            {y + "/" + m + "/" + d}
            (<span style={(idx === 0 || idx === 6) ? { color: "#FF3F3F" } : {}}>{w}</span>)
        </span>
    )
}

// export const getInitialWorkReport = (initial, size) => {
//     let times = new Array(0)
//     for (let idx = 0; idx < size; idx++) {
//         times.push(getInitialWorkRow(initial + idx)
//         )
//     }
//     const objects = {
//         times: times,
//         dates: { today: null, next: null }
//     }
//     return objects
// }

// export const getInitialWorkRow = (time) => {
//     return {
//         time: time,
//         today: [
//             {
//                 text: '',
//                 const: {
//                     constId: '',
//                     name: ''
//                 }

//             },
//             {
//                 text: '',
//                 const: {
//                     constId: '',
//                     name: ''
//                 }

//             }
//         ],
//         next: [
//             {
//                 text: ''
//             },
//             {
//                 text: ''
//             }
//         ],
//     }
// }

// export const getInitialConstReport = (date) => {
//     return {
//         date: date, // 対象日付
//         workDetail: '', // 作業内容
//         safetyInspection: [], // 安全点検・確認項目
//         riskPrediction: [],// 危険予知活動
//         actionForRisk: [], // 危険から身を守るための行動項目
//         workingRange: '',   // 作業範囲
//         vehicle: '',    // 使用車両
//         dedura: [],  // 作業人員（出面）
//         juki: [], // 重機
//         safetyGoal: '', // 本日の安全目標
//         introspection: '',  // 反省
//         nearMiss: '',   // ヒヤリハット
//         progress: '',   // 進捗状況
//         openingDate: '',    // 開所日
//         instruction: '',    // 指示内容と報告
//     }
// }

