import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { sessionUserState, cameraSettingState, errorState } from "../../recoil/atoms";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MeService from "../../services/meService";
import { getReqErrorMessage } from "../../utils/errorUtil";


const Setting = () => {
  const [open, setOpen] = useState(false);
  const sessionUser = useRecoilValue(sessionUserState);
  const [cameraSetting, setCameraSetting] = useRecoilState(cameraSettingState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopySettingChange = (event) => {
    const inputCameraSetting = {
      ...cameraSetting, 
      userId: sessionUser.userId,
      copy: { ...cameraSetting.copy, [event.target.name]: event.target.checked},
    };
    //console.log("inputCameraSetting:↓");
    //console.log(inputCameraSetting);

    MeService.putCameraSetting(inputCameraSetting).then(
        (response) => {
          console.log("meService putCameraSetting");
          console.log(response.data);
          // state更新
          setCameraSetting(response.data);
        },
        (error) => {
          setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
        }
      );
  };

  const handleImageUploadSettingChange = (event) => {
    const inputCameraSetting = {
      ...cameraSetting, 
      userId: sessionUser.userId,
      imageUpload: { ...cameraSetting.imageUpload, [event.target.name]: event.target.value},
    };
    //console.log("inputCameraSetting:↓");
    //console.log(inputCameraSetting);

    MeService.putCameraSetting(inputCameraSetting).then(
        (response) => {
          console.log("meService putCameraSetting");
          console.log(response.data);
          // state更新
          setCameraSetting(response.data);
        },
        (error) => {
          setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
        }
      );
  };

  return (
    <div>
      <IconButton
        className="const-icon-button"
        color="primary"
        aria-label="delete info"
        component="div"
        onClick={handleOpen}
      >
        <SettingsOutlinedIcon fontSize="large" />
        <div className="const-icon-label">設定</div>
      </IconButton>
      <Dialog
        keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
      //fullScreen
      >
        <DialogTitle>設定</DialogTitle>
        <DialogContent>
          <Divider />
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">複写設定</FormLabel>
              <FormHelperText>撮影・画像登録時、最後尾の写真情報から複写が可能です。以下から複写する項目を選択して下さい。</FormHelperText>
              <FormGroup row={true}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cameraSetting.copy.constClassId}
                      onChange={handleCopySettingChange}
                      name="constClassId"
                    />
                  }
                  label="工種"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={cameraSetting.copy.constTypeId}
                      onChange={handleCopySettingChange}
                      name="constTypeId"
                    />
                  }
                  label="工種詳細"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={cameraSetting.copy.floor}
                      onChange={handleCopySettingChange}
                      name="floor"
                    />
                  }
                  label="階数"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={cameraSetting.copy.description}
                      onChange={handleCopySettingChange}
                      name="description"
                    />
                  }
                  label="詳細"
                />
              </FormGroup>
            </FormControl>
          </div>
          <Divider />
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">撮影日設定</FormLabel>
              <FormHelperText>画像登録時、撮影日を以下で登録します。</FormHelperText>
              <RadioGroup
                row={true}
                name="day"
                value={cameraSetting.imageUpload.day}
                onChange={handleImageUploadSettingChange}
              >
                <FormControlLabel
                  value="lastModified"
                  control={<Radio />}
                  label="画像ファイルの日付"
                />
                <FormControlLabel
                  value="today"
                  control={<Radio />}
                  label="今日"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Setting;
