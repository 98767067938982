import { formatDate } from '../../utils/Utils'

import './InfoBox.css'

const InfoBox = (props) => {

    return (
        <div className="InfoBox" {...props}>
            <div className="Title">{props.title}</div>
            <div className="Value">{getDisplayString(props.value)}</div>
        </div>
    )
}

const getDisplayString = (value) => {
    if (!value) {
        return value
    }

    if (typeof (value) !== 'object') {
        return value
    }

    let toString = Object.prototype.toString
    if (toString.call(value) === '[object Date]') {
        value = formatDate(value)
    }
    return value
}

export default InfoBox