import React, {useState} from "react";
import {  useSetRecoilState } from "recoil";
import { photoInputsState, photosState, errorState } from "../../recoil/atoms";
import CameraService from "../../services/cameraService";
import shallowequal from "shallowequal";
import produce from 'immer';
import { formatIso } from '../../utils/dateUtil';
import { formatPhoto } from "../../utils/photoUtil";
import { IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getReqErrorMessage } from "../../utils/errorUtil";
import CircularProgressWithContent from "../common/CircularProgressWithContent";


const PhotoUpdate = (props) => {
  //console.log("PhotoUpdate called");
  const setPhotos = useSetRecoilState(photosState);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用
  const [updating, setUpdating] = useState(false);// 更新中フラグ
  const disabled = updating ? true : props.obj.disabled ? true : shallowequal(props.obj, props.input);

  const handleClick = (event) => {
    //console.log(event);

      // ダブルクリック操作抑止のため、更新完了するまで一時的に編集不可にしておく
      setUpdating(true);

      // 写真情報
      // 日付をiso8601拡張形式にする
      const photo = {
        ...props.input,
        day: formatIso(props.input.day),
      };

      CameraService.updatePhoto(props.input._id, photo)
      .then(
        response =>{
          //console.log(response.data);
          const updatedPhoto = formatPhoto(response.data);
          // state変更
          setPhotos((objects) => {
            const updatedObjects = produce(objects, draft => {   
              const idx = draft.findIndex((obj) => obj._id === props.input._id);
              if (idx !== -1) {
                draft[idx] = updatedPhoto;
              } else {
                console.log("not found")
              }
            });

            return updatedObjects;
          });
          
          setPhotoInputs((objects) => {
            const updatedObjects = produce(objects, draft => {
              const idx = draft.findIndex((obj) => obj.input._id === props.input._id);
              if (idx !== -1) {
                draft[idx] = { equality: true, input: updatedPhoto };
              } else {
                console.log("not found")
              }
            });
            
            return updatedObjects;
          });

          // 更新完了
          setUpdating(false);
        },
        (error) => {
          setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "更新エラーが発生しました。" })

          // 更新に失敗した場合、編集できるように戻す
          setUpdating(false);
        }
      );
  };

  return (
    <div>
      {!updating && (
      <IconButton
        // className="const-icon-button" 
        color="primary"
        aria-label="upload info"
        component="div"
        style={{padding: '2px'}}
        onClick={handleClick}
        disabled={disabled}
      >
        <CheckCircleIcon fontSize="large" />
        {/* <div className="const-icon-label">情報更新</div> */}
      </IconButton>
      )}
      {updating && (
        <CircularProgressWithContent size={30}>
          <Typography variant="caption" component="div" color="text.secondary">
            更新
          </Typography>
        </CircularProgressWithContent>
      )}
    </div>
  );
};
export default PhotoUpdate;
