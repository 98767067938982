import {http} from './config';

const requestPath = "/v1/me";

const me = () => {
  return http.get(`${requestPath}`);
};

const getToken = () => {
  return http.get(`${requestPath}/token`);
};

// 個人設定を取得（本体アプリから）
const findPersonalSetting = () => {
  return http.get(`${requestPath}/personalSetting`)
}

const getCameraSetting = () => {
  return http.get(`${requestPath}/cameraSetting`);
};

const putCameraSetting = (cameraSetting) => {
  return http.put(`${requestPath}/cameraSetting`, cameraSetting);
};

const exports = {
  me,
  getToken,
  findPersonalSetting,
  getCameraSetting,
  putCameraSetting,
}; 

export default exports;