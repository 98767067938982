import React, { useState } from "react";
import {useSetRecoilState, useRecoilState } from "recoil";
import { photosState, photoInputsState, activeIndexState, errorState } from "../../recoil/atoms";
import CameraService from "../../services/cameraService";
import produce from "immer";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { getReqErrorMessage } from "../../utils/errorUtil";

const PhotoDelete = (props) => {
  //console.log("PhotoDelete called");
  const disabled = props.obj.disabled ? true : false;
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useRecoilState(photosState);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const setActiveIndex = useSetRecoilState(activeIndexState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = (event) => {
    console.log("delete");

    CameraService.remove(props.obj._id).then(
      (response) => {
        //console.log(response.data);
        // state変更
        setPhotos((objects) => {
          const updatedObjects = produce(objects, (draft) => {
            const idx = draft.findIndex((obj) => obj._id === props.obj._id);
            if (idx !== -1) {
              draft.splice(idx, 1);
            }
          });

          return updatedObjects;
        });

        setPhotoInputs((objects) => {
          const updatedObjects = produce(objects, (draft) => {
            const idx = draft.findIndex((obj) => obj.input._id === props.obj._id);
            if (idx !== -1) {
              draft.splice(idx, 1);
            }
          });

          return updatedObjects;
        });

        // スライド状態の変更
        setActiveIndex((currentIndex) => {
          const lastIndex = photos.length -1;

          // 最後を削除した場合1つ手前にする
          if(currentIndex === lastIndex) {
            return currentIndex - 1;
          }

          return currentIndex;
        });
      },
      (error) => {
        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
      }
    );
    // ダイアログを閉じる
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        // className="const-icon-button"
        color="secondary"
        aria-label="delete info"
        component="div"
        style={{padding: '2px'}}
        onClick={handleOpen}
        disabled={disabled}
      >
        <HighlightOffOutlinedIcon fontSize="large" />
        {/* <div className="const-icon-label">削除</div> */}
      </IconButton>
      <Dialog
        //keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
        //fullScreen
      >
        <DialogTitle>削除</DialogTitle>
        <DialogContent>写真情報を削除します。よろしいですか？</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PhotoDelete;
