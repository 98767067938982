import React, { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { photoInputsState, constInfoState } from "../recoil/atoms";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import shallowequal from "shallowequal";
import produce from "immer";
import ClipboardCopy from "./actions/ClipboardCopy";
import PhotoUpdate from './actions/PhotoUpdate';
import PhotoDelete from './actions/PhotoDelete';
import CalendarDialog from "./common/CalendarDialog";
import {formatDate} from "../utils/Utils";
import {getConstCategoryAndConstIdValue, splitConstCategoryAndConstIdValue} from "../utils/photoUtil";

const PhotoInfo = (props) => {
  console.log("PhotoInfo called");

  const disabled = props.obj.disabled ? true : false;
  const [input, setInput] = useState(props.obj);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const constInfo = useRecoilValue(constInfoState);
  const [constClassId, setConstClassId] = useState(props.obj.constClassId);

  // 検索によるstateの初期化
  useEffect(() => {
    //console.log("PhotoInfo useEffect1 called!");
    setInput(props.obj)
  }, [props.obj]);

  // stateの入力情報が変更->atomの入力情報に反映
  useEffect(() => {
    //console.log("PhotoInfo useEffect2 called!");
    setPhotoInputs((prevInputs) => {
      const updatedObjects = produce(prevInputs, (draft) => {
        let photoInput = { equality: true, input: input };
        if (!shallowequal(props.obj, input)) {
          photoInput = { equality: false, input: input };
        }

        const idx = draft.findIndex((e) => e.input._id === props.obj._id);
        if (idx !== -1) {
          draft[idx] = photoInput;
        } else {
          draft.push(photoInput);
        }
      });

      return updatedObjects;
    });
    // props.objに対する依存警告の抑制。props.objはここでは比較のために利用するだけで、非依存。
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [input, setPhotoInputs]);

  const handleInputChange = (event) => {
    //console.log(input);
    // stateを変更
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };

  const handleDayChange = (changedDay) => {
    setInput({ ...input, "day":  changedDay });
  }

  const handleConstClassChange = (event) => {
    console.log("handleConstClassChange");
    console.log(event.target);
    // stateを変更
    const { value } = event.target;

    // 未選択時
    if(value === -1) {
      setInput({
        ...input,
        constCategory: null,
        constCategoryName: null,
        constClassId: value,
        constClassName: null,
        constTypeId: -1, // 工種詳細は未選択に戻す
        constTypeName: null,
      });
      setConstClassId(value);
      return;
    }

    // 選択時
    const selected = splitConstCategoryAndConstIdValue(value);
    console.log('selected.constCategory: ' + selected.constCategory);
    console.log('selected.constClassId: ' + selected.constClassId);
    constInfo.forEach((constCategory) => {
      if(constCategory.constCategory === selected.constCategory) {
        if(constCategory.constClasses.length > 0) {
          const found = constCategory.constClasses.find((constClass) => {
            return constClass.id === selected.constClassId;
          });
          if(found) {
            console.log("constClassId found");
            setInput({
              ...input,
              constCategory: constCategory.constCategory,
              constCategoryName: constCategory.constCategoryName,
              constClassId: found.id,
              constClassName: found.constClassName,
              constTypeId: -1, // 工種詳細は未選択に戻す
              constTypeName: null,
            });
            setConstClassId(found.id);
          }
        } else {
          // 大分類のみ設定されているケース
          console.log("constCategory found");
          setInput({
            ...input,
            constCategory: constCategory.constCategory,
            constCategoryName: constCategory.constCategoryName,
            constClassId: -1,
            constClassName: null,
            constTypeId: -1, // 工種詳細は未選択に戻す
            constTypeName: null,
          });
          setConstClassId(-1);
        }
        return;
      }
    });
  };

  const handleConstTypeChange = (event) => {
    console.log("handleConstTypeChange");
    console.log(event.target);
    // stateを変更
    const { value } = event.target;

    // 未選択時
    if(value === -1) {
      setInput({
        ...input,
        constTypeId: -1,
        constTypeName: null,
      });

      return;
    }

    // 選択時
    constInfo.forEach((constCategory) => {
      const foundClass = constCategory.constClasses.find((constClass) => {
        return constClass.id === constClassId;
      });

      if(!foundClass) {
        return;
      }
      
      const found = foundClass.constTypes.find((constType) => {
        return constType.id === value;
      });

      if(found) {
        setInput({
          ...input,
          constTypeId: found.id,
          constTypeName: found.constTypeName,
        });
      }
    });
  };

  return (
      <div className="Photo-info">
        <div className="Photo-info-day">
          {/* <TextField
            name="day"
            label="日付"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            //defaultValue={new Date()}
            value={input.day}
            onChange={handleInputChange}
          /> */}
          {/* <span>日付</span> */}
          <span>{formatDate(input.day)}</span>
          {!disabled &&
            <CalendarDialog
              name="day"
              value={input.day}
              onChange={handleDayChange}
            />
          }
        </div>
        <div>
          <TextField
            name="constClassId"
            label="工種"
            select
            // SelectProps={{
            //   native: true,
            // }}
            InputLabelProps={{ shrink: true }}
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            defaultValue={-1}
            value={getConstCategoryAndConstIdValue(input.constCategory, input.constClassId)}
            onChange={handleConstClassChange}
            disabled={disabled}
          >
            <MenuItem key={-1} value={-1}>
              未選択
            </MenuItem>
            {constInfo.map((constCategory) => {
              if(constCategory.constClasses.length > 0) {
                return constCategory.constClasses.map((constClass) => {
                  return (
                    <MenuItem key={getConstCategoryAndConstIdValue(constCategory.constCategory, constClass.id)} value={getConstCategoryAndConstIdValue(constCategory.constCategory, constClass.id)}>
                      {constCategory.constCategoryName}-{constClass.constClassName}
                    </MenuItem>
                  );
                });
              } else {
                // 大分類のみ設定されているケース
                return (
                  <MenuItem key={getConstCategoryAndConstIdValue(constCategory.constCategory, -1)} value={getConstCategoryAndConstIdValue(constCategory.constCategory, -1)}>
                    {constCategory.constCategoryName}
                  </MenuItem>
                );
              }
            })}
          </TextField>
        </div>
        <div>
          <TextField
            name="constTypeId"
            label="工種詳細"
            select
            InputLabelProps={{ shrink: true }}
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            defaultValue={-1}
            value={input.constTypeId}
            onChange={handleConstTypeChange}
            disabled={disabled}
          >
            <MenuItem key={-1} value={-1}>
              未選択
            </MenuItem>
            {constInfo.map((constCategory) => {
              return constCategory.constClasses.filter((constClass) => {
                return constClass.id === constClassId;
              }).map((constClass) => {
                return constClass.constTypes.map((constType) => {
                  return (
                    <MenuItem key={constType.id} value={constType.id}>
                      {constType.constTypeName}
                    </MenuItem>
                  );
                })
              });
            })}
          </TextField>
        </div>
        <div className="Photo-info-clipboard">
          <TextField
            name="floor"
            label="階数"
            type="text"
            InputProps={{
              endAdornment: <InputAdornment position="end">階</InputAdornment>,
            }}
            size="small"
            margin="dense"
            style={{ width: "8rem" }}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            //defaultValue={null}
            value={input.floor}
            onChange={handleInputChange}
            disabled={disabled}
          />
          <ClipboardCopy label="詳細" text={input.description} />
        </div>
        <div>
          <TextField
            name="description"
            label="詳細"
            type="text"
            multiline
            rows={3}
            size="small"
            margin="dense"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            //defaultValue={null}
            value={input.description}
            onChange={handleInputChange}
            disabled={disabled}
          />
        </div>
        <div className="Photo-info-action">
          <PhotoUpdate obj={props.obj} input={input} />
          <PhotoDelete obj={props.obj} />
        </div>
      </div>
  );
};

export default React.memo(PhotoInfo);
