import React, { useState } from "react";
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const SWUpdateDialog = ({registration}) => {
  const [open, setOpen] = useState(!!registration.waiting);
  
  const handleUpdate = () => {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
    setOpen(false);
    window.location.reload(true);
  };

  return (
      <Dialog
        open={open}
        //fullScreen
      >
        {/* <DialogTitle></DialogTitle> */}
        <DialogContent>
          新しいバージョンがリリースされました
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>アップデート</Button>
        </DialogActions>
      </Dialog>
  );
};
export default SWUpdateDialog;
