import "./ImageUpload.css";

import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  photosState,
  photoInputsState,
  cameraSettingState,
  activeIndexState,
  searchConditionState,
  errorState,
} from "../../recoil/atoms";
import { IconButton } from "@mui/material";
//import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import CameraService from "../../services/cameraService";
import { formatIso } from "../../utils/dateUtil";
import { formatPhoto, copyPhoto, isAllowedExt } from "../../utils/photoUtil";
import produce from "immer";
import { getReqErrorMessage } from "../../utils/errorUtil";

const ImageUpload = () => {
  const [photos, setPhotos] = useRecoilState(photosState);
  const setPhotoInputs = useSetRecoilState(photoInputsState);
  const cameraSetting = useRecoilValue(cameraSettingState);
  const setActiveIndex = useSetRecoilState(activeIndexState);
  const searchCondition = useRecoilValue(searchConditionState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用

  const handleChange = (event) => {
    if (!(event.target.files && event.target.files[0])) {
      event.target.value = null; // アップロードファイルをクリア
      setError({ msg: "アップロードエラー：画像ファイルがありません。", open: true, title: "エラーが発生しました。" })
      return;
    }

    // 写真情報
    const now = new Date();
    let photoInput = {
      constId: searchCondition.constId,
      dateTimeOriginal: formatIso(now),
      day: formatIso(now, "start"),
    };

    // 複写
    const total = photos.length;
    if (total > 0) {
      // 最後尾の写真情報から複写する
      const lastIndex = total - 1;
      const copied = copyPhoto(photos[lastIndex], cameraSetting);

      photoInput = {
        ...photoInput,
        ...copied,
      };
    }
    console.log(photoInput);

    const files = Array.from(event.target.files);
    
    // 画像登録設定
    if(cameraSetting.imageUpload.day === "lastModified") {
      // ファイルの最終更新日の場合
      // ファイルの最終更新日の昇順に並び替える
      files.sort((a, b) => a.lastModified - b.lastModified);
    } 
    
    const addTotal = files.length;
    let photoInputs = [];
    for (let i = 0; i < addTotal; i++) {
      photoInputs.push(photoInput);
    }

    // パフォーマンスのためまず写真情報のみアップロード
    CameraService.createPhotos(photoInputs).then(
      (response) => {
        //console.log(response.data);
        const resultArray = response.data;
        const createdPhotos = resultArray.map((result) => {
          return {
            ...formatPhoto(result),
            disabled: true, // 編集無効フラグ。写真イメージのアップロードが完了するまでユーザーによる編集・更新操作をさせない目的で使用
          };
        });

        // state変更
        setPhotos((objects) => {
          const updatedObjects = produce(objects, (draft) => {
            draft.push(...createdPhotos);
          });
          return updatedObjects;
        });

        // 登録した写真にスライド
        setActiveIndex(total);

        function delay(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }
        
        //次に写真イメージをアップロード
        const upload = async () => {

          for(const [index, file] of files.entries()) {
            
            if(!isAllowedExt(file.name)) {
              console.log("file.name:" + file.name);
              setError({ msg: "写真はJPEGファイルである必要があります。\n元のファイル名:"+file.name, open: true, title: "エラーが発生しました。" })
              // 写真イメージのアップロードで失敗した場合、削除できるようにする
              setPhotoError(resultArray[index]._id);

              continue;
            }
            
            // 画像登録設定
            let photoInput = null;
            if(cameraSetting.imageUpload.day === "lastModified") {
              // ファイルの最終更新日の場合、撮影日を変更する
              photoInput = {
                ...resultArray[index],
                dateTimeOriginal: formatIso(file.lastModified),
                day: formatIso(file.lastModified, "start"),
              };
              //alert(formatIso(file.lastModified));
            } else {
              // 今日の場合、dateTimeOriginalを変更する。パフォーマンス対応：新規ファイル名の枝番処理をなるべくさせないため。
              photoInput = {
                ...resultArray[index],
                dateTimeOriginal: formatIso(new Date()),
              };
            }

            CameraService.updateImage(resultArray[index]._id, file, photoInput).then(
              (response) => {
                //console.log(response.data);
                const updatedPhoto = formatPhoto(response.data);
                // state変更
                setPhotos((objects) => {
                  const updatedObjects = produce(objects, (draft) => {
                    const idx = draft.findIndex(
                      (obj) => obj._id === resultArray[index]._id
                    );
                    if (idx !== -1) {
                      draft[idx] = updatedPhoto;
                    } else {
                      console.log("not found");
                    }
                  });
                  console.log("setPhotos");
                  return updatedObjects;
                });

                setPhotoInputs((objects) => {
                  const updatedObjects = produce(objects, (draft) => {
                    const idx = draft.findIndex(
                      (obj) => obj.input._id === resultArray[index]._id
                    );
                    if (idx !== -1) {
                      draft[idx] = { equality: true, input: updatedPhoto };
                    } else {
                      console.log("not found");
                    }
                  });
                  console.log("setPhotoInputs");
                  return updatedObjects;
                });
              },
              (error) => {
                console.log("photo upload fail");
                setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })

                // 写真イメージのアップロードで失敗した場合、削除できるようにする
                setPhotoError(resultArray[index]._id);
              }
            );
            // なるべくリクエストを順次送信することでstateの更新もなるべく順次されるように調整
            await delay(500);// 参考)現状iphoneで撮影した1画像ファイル(数Ｍbyte)のアップに2秒程度かかっている
          }
        };

        upload();
      },
      (error) => {
        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
      }
    );

    event.target.value = null; // アップロードファイルをクリア
  };

  const setPhotoError = (photoId) => {
    // 写真イメージのアップロードで失敗した場合、削除できるようにする
    setPhotos((objects) => {
      const updatedObjects = produce(objects, (draft) => {
        const idx = draft.findIndex(
          (obj) => obj._id === photoId
        );
        if (idx !== -1) {
          draft[idx].disabled = false;// 削除できるように編集無効フラグをoffにする
        } else {
          console.log("not found");
        }
      });
      console.log("setPhotos");
      return updatedObjects;
    });
  };

  return (
    <div className="ImageUpload">
      <input
        id="upload-image"
        type="file"
        accept="image/jpeg"
        multiple
        //accept=".heif, .heic"
        onChange={handleChange}
      />
      <label htmlFor="upload-image">
        <IconButton
          className="const-icon-button"
          color="primary"
          aria-label="upload image"
          component="div"
        >
          <PhotoSizeSelectActualOutlinedIcon fontSize="large" />
          <div className="const-icon-label">画像登録</div>
        </IconButton>
      </label>
    </div>
  );
};

export default ImageUpload;
