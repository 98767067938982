import { atom } from 'recoil';
import { formatDate } from '../utils/dateUtil';

// 写真情報の一覧
export const photosState = atom({
  key: 'photos',
  default: [],
});

// 現在アクティブな状態にある写真情報のインデックス番号
export const activeIndexState = atom({
  key: 'activeIndex',
  default: 0, 
});

// 写真の入力情報の一覧
// 画像スライダーで、入力情報が変更されたかどうかをサムネイル画像コンポーネントと共有させるためにatom化する
// photoStateとの違いは、こちらが入力変更した情報を保持するのに対し、photoStateの方はDBから取得した情報を保持している点。
export const photoInputsState = atom({
  key: 'photoInputs',
  default: [],
});

// 写真情報の検索条件
export const searchConditionState = atom({
  key: 'searchCondition',
  default: {
    constId: "", 
    constName: "", 
    day: formatDate(new Date()),
  },
});

// カメラアプリの設定（ユーザーごとにある）
export const cameraSettingState = atom({
  key: 'cameraSetting',
  default: {
    _id: null,
    userId: null,
    copy: {// 複写設定(カメラ撮影・画像登録時、現在表示中の最後尾の写真情報から複写する設定)
      constClassId: false, // 工種
      constTypeId: false, // 工種詳細
      floor: false, // 階数
      description: false, // 詳細
    },
    imageUpload: {// 画像登録設定
      // 画像登録時、撮影日をどの日にするか
      day: "lastModified", // lastModified: ファイルの最終更新日, today: 今日
    },
  },
});

// 案件の全データを工番のマップ構造で保持する
export const constListData = atom({
  key: 'constListData',
  default: new Map(),
});

// ログインセッション
export const sessionUserState = atom({
  key: 'sessionUser',
  default: {
    email: "",
    firstName: "",
    groupId: "",
    groupName: "",
    lastName: "",
    name: "",
    roles: [],
    userId: "",
    userName: "",
    superiorId: "",
    superiorName: "",
  },
});

// 現在選択中の工番情報
export const constInfoState = atom({
  key: 'constInfo',
  default: [],
});

// エラーメッセージ表示用
export const errorState = atom({
  key: 'errorState',
  default: { msg: null, open: false, title: null },
})
