import { formatDate } from "./dateUtil";

// 写真アップロードで許可するファイル拡張子
const allowedExts = ["jpg", "jpeg", "png"];// heic, heifは現状javaでサムネイル作成できないため不可

// 許可された拡張子かどうか
export const isAllowedExt = (filename) => {
  const ext = getExt(filename);
  if(allowedExts.indexOf(ext) === -1) {
    return false;
  }
  return true;
};

// ファイル拡張子を返す
export const getExt = (filename) => {
  const pos = filename.lastIndexOf('.');
	if (pos === -1) return '';
	return filename.slice(pos + 1).toLowerCase();// 小文字で返す
}

export const formatPhoto = (obj) => {
  // サーバー側のデータがnullの場合を考慮して、初期値をマージしてセット
  const formated = {
    ...obj,
    ...{
      day: formatDate(obj.day),
      constClassId: obj.constClassId != null ? obj.constClassId : -1,
      constTypeId: obj.constTypeId != null ? obj.constTypeId : -1,
      floor: obj.floor ? obj.floor : "",
      description: obj.description ? obj.description : "",
    },
  };
  return formated;
};

// 複写設定に基づき、指定した写真情報の一部をコピーして返す（複写しない場合は初期値）
export const copyPhoto = (photo, cameraSetting) => {

  const copied = {
    // 工種
    constCategory: cameraSetting.copy.constClassId ? photo.constCategory : null,
    constCategoryName: cameraSetting.copy.constClassId ? photo.constCategoryName : null,
    constClassId: cameraSetting.copy.constClassId ? photo.constClassId : -1,
    constClassName: cameraSetting.copy.constClassId ? photo.constClassName : null,
    // 工種詳細
    constTypeId: cameraSetting.copy.constTypeId ? photo.constTypeId : -1,
    constTypeName: cameraSetting.copy.constTypeId ? photo.constTypeName : null,
    // 階数
    floor: cameraSetting.copy.floor ? photo.floor : "",
    // 詳細
    description: cameraSetting.copy.description ? photo.description : "",
  };

  return copied;
};

// 工種入力フォームの値(大分類,中分類)を返す
const separator = ',';
export const getConstCategoryAndConstIdValue = (constCategory, constClassId) => {

  if(constCategory === null) {
    // 大分類、中分類ともにない
    if(constClassId === -1) {
      return -1;// 未選択値
    } else {
      // 大分類はないが中分類があることは仕様上あり得ない
      console.log("error: constCategory: " + constCategory + ", constClassId: " + constClassId );
      return -1;
    }
  }
  
  // 大分類はあるが中分類がない場合
  if(constClassId === -1) {
    return constCategory + separator + -1;
  }

  return constCategory + separator + constClassId;
};
// 工種入力フォームの値(大分類,中分類)を分割して返す
export const splitConstCategoryAndConstIdValue = (constCategoryAndConstIdValue) => {
  if(constCategoryAndConstIdValue === -1) {
    // 未選択のケース
    return -1;
  }

  const arr =  constCategoryAndConstIdValue.split(separator);
  return {
    constCategory: arr[0],
    constClassId: parseInt(arr[1]), // constClassIdはintである前提
  };
};