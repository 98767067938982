import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import LoginService from "../../services/loginService";

const Logout = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = (event) => {
    LoginService.logout();
  };

  return (
    <>
      <IconButton
        className="const-icon-button"
        color="primary"
        aria-label="logout"
        component="span"
        onClick={handleOpen}
      >
        <ExitToAppOutlinedIcon fontSize="large" />
        {/* <div className="const-icon-label">ログアウト</div> */}
      </IconButton>
      <Dialog
        keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
        //fullScreen
      >
        <DialogTitle>ログアウト</DialogTitle>
        <DialogContent>
          ログアウトします。よろしいですか？
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Logout;
