import { http } from "./config";

const requestPath = "/v1/camera";

const findAll = (query, paging, fields) => {
  let params = [];

  if(fields !== null && fields.length) {
    params.push("fields=" + fields.join(','));
  }
  if(paging) {
    const offset = paging.limit * (paging.page - 1);
    params.push(`limit=${paging.limit}&offset=${offset}`);

    paging.sort.forEach((s) => {
      params.push(`sort=${s.field},${s.direction}`);
    });   
  }
  if(query) {
    params.push("query=" + encodeURIComponent(query));
 }
  
  return http.get(`${requestPath}/${params.length ? "?" + params.join("&") : ""}`);
};

const findOne = (_id) => {
  return http.get(`${requestPath}/${_id}`);
};

const createPhoto = (photo) => {
  return http.post(`${requestPath}`, photo);
};

const createPhotos = (photos) => {
  return http.post(`${requestPath}/photos`, photos);
};

const updatePhoto = (_id, photo) => {
  return http.put(`${requestPath}/${_id}`, photo);
};

const updateImage = (_id, file, photo = null) => {
  // FormDataでデータを送る時はblobか文字列にしなければならない仕様
  const data = new FormData();
  if (photo !== null) {
    // 写真情報はオプション扱い
    data.append('photo', JSON.stringify(photo));
  }
  data.append("file", file);

  return http.put(`${requestPath}/images/${_id}`, data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

const remove = (_id) => {
  return http.delete(`${requestPath}/${_id}`);
};

const exports = {
  findAll,
  findOne,
  createPhoto,
  createPhotos,
  updatePhoto,
  updateImage,
  remove,
};

export default exports;
