import "./Header.css";

import React from "react";
import ConstSelector from "./actions/ConstSelector";
import Counter from "./Counter";


const Header = () => {

  return (
    <div className="Header">
      <ConstSelector />
      <Counter className="Header-counter" />
    </div>
  );
};
export default Header;
