import "./Photo.css";

import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { activeIdSelector } from "../recoil/selectors";
import { Typography } from "@mui/material";
import { photoThumbnailUrl } from "../services/config";
import CircularProgressWithContent from "./common/CircularProgressWithContent";
import PhotoInfo from "./PhotoInfo";

const noimageSrc = "/noimage_m.png";

const Photo = (props) => {
  console.log("Photo called");

  const activeId = useRecoilValue(activeIdSelector);
  const [hiddenPhotoInfo, setHiddenPhotoInfo] = useState(true);
  const imgSrc = props.obj.thumbMPath
    ? `${photoThumbnailUrl}/${props.obj.thumbMPath}`
    : null;
  const [imgLoading, setImgLoading] = useState(true);
  
  if(imgLoading && !imgSrc && !props.obj.disabled) {
    // イメージのアップロード中でない場合（=通常の検索結果表示）で、イメージがないケースはnoimageを表示
    setImgLoading(false);
  }
  //console.log(input);

  // パフォーマンス対応：写真情報の遅延表示（スライド時に表示）
  useEffect(() => {
    //console.log("Photo useEffect1 called!");

    if (props.obj._id === activeId) {
      //setHiddenPhotoInfo(false);
      // ↑だと、スライド時のアニメーションに若干もたつきがある。
      // 若干変更のタイミングをずらすことで、スライド時の表示も若干スムーズになる気がする。
      setTimeout(() => {
        setHiddenPhotoInfo(false);
      }, 10);
    }
  }, [props.obj, activeId]);

  const handleLoadImage = () => {
    setImgLoading(false);
  };

  // 画像リンク切れのケースもnoimageを表示
  const handleErrorImage = (event) => {
    event.target.src = noimageSrc;
  };

  return (
    <div className="Photo">
      <div className="Photo-image">
        {imgLoading && (
          <CircularProgressWithContent>
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {imgSrc ? "読み込み中" : "アップロード中"}
            </Typography>
          </CircularProgressWithContent>
        )}
        {imgSrc && (
          <img
            src={imgSrc}
            alt={props.obj._id}
            onLoad={handleLoadImage}
            onError={handleErrorImage}
            style={{ display: imgLoading ? "none" : "initial" }}
          />
        )}
        {!imgLoading && !imgSrc && (
          <img
            src={noimageSrc}
            alt="画像がありません"
          />
          )}
      </div>
      {hiddenPhotoInfo ? (
        <div className="Photo-info"></div>
      ) : (
        <PhotoInfo obj={props.obj} />
      )}
    </div>
  );
};

export default React.memo(Photo);
