import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import React　from "react";
import ReactCalendar from 'react-calendar'
import InfoBox from './InfoBox';
import moment from 'moment';

/**
 * 日付選択コンポーネント
 * 本体アプリと基本的に同じ見た目、操作姓に統一する
 */ 
const Calendar = (props) => {
  console.log("Calendar called");

  const {value, onChange} = props;

  // ReactCalendar用に日付文字列（YYYY-MM-DD）からDateオブジェクトに変更
  const targetDate = moment(value).toDate();
  //console.log(targetDate);

  const handleChange = (changedDate) => {
    // Dateオブジェクトから日付文字列に変更して、親のonChange関数に渡す
    onChange(moment(changedDate).format("YYYY-MM-DD"));
  }

  return (
    <div style={{ display: "block", positoin: "relative", width: "270px", maxHeight: "330px" }}>
      <InfoBox style={{ position: "relative", width: "270px" }} title="撮影日" value={targetDate}></InfoBox>
      <ReactCalendar
        calendarType="US"
        formatDay={(locale, date) => { return '' + date.getDate() }}
        onChange={handleChange}
        value={targetDate}
      />
    </div>
  );
};
export default Calendar;
