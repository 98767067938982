import React, { useState, useEffect, useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { searchConditionState, photoInputsState, constInfoState, constListData, errorState } from "../../recoil/atoms";
import { Typography, Button } from "@mui/material";
import ConstIdDialog from "../common/ConstIdDialog";
import { formatDate } from '../../utils/dateUtil';
import ProjectService from "../../services/projectService";
import { getReqErrorMessage } from "../../utils/errorUtil";

// 工事案件の選択を行うコンポーネント
const ConstSelector = () => {
  console.log("ConstSelector called");
  const [open, setOpen] = useState(false);
  const [canCancel, setCanCancel] = useState(true);
  const setConstList = useSetRecoilState(constListData) // 案件一覧データ（マップ構造）
  const [searchCondition, setSearchConditionState] = useRecoilState(searchConditionState);
  const photoInputs = useRecoilValue(photoInputsState);
  const setConstInfoState = useSetRecoilState(constInfoState);
  const setError = useSetRecoilState(errorState);// エラーメッセージ表示用

  // メッセージ
  let message = "";
  const idx = photoInputs.findIndex((e) => e.equality === false);
  if (idx !== -1) {
    // 未更新のデータがある場合は確認メッセージを出す
    message =
      "編集中のデータが存在します。<br/>編集内容は消えてしまいますが宜しいですか？";
  }

  const handleOpen = useCallback(() => {
    // ダイアログを開く度に最新データ取得するようにする。
    // 工事案件は更新頻度が高く、また本アプリはPWAのため画面リフレッシュが発生しないため、ここで都度最新を取得する。
    ProjectService.findAll(null, null, []).then(
      (response) => {
        console.log("projectService findAll");
        //console.log(response.data);
        let map = new Map()
        response.data.objects.forEach((v) => {
          map.set(v.constId, v)
        });
        // state更新
        setConstList(map);
      },
      (error) => {
        setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
      }
    );

    setOpen(true);
  },[setConstList, setError]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChoice = (project) => {
    console.log("ConstSelector handleChoice");
    console.log(project);
    // 同じオブジェクトだとsetしてもstateは変更されない。
    // 検索条件が同じでも必ず検索させるために、新しいオブジェクトとしてセットする。
    setSearchConditionState({
      constId: project.constId,
      constName: project.name,
      day: formatDate(new Date()), // 日付は当日にリセットしておく
    });

    setOpen(false);
  };

  // 工事番号に変更があれば行う処理
  useEffect(() => {
    console.log("ConstSelector useEffect called");
    
    if(!searchCondition.constId) {
      // 工事番号が未設定の場合にダイアログを出してまず工事番号を確定させる(初回時を想定)
      setCanCancel(false);
      handleOpen();
    } else {
      setCanCancel(true);

      // 工事番号が決定したら工種取得する
      ProjectService.constInfo(searchCondition.constId).then(
        (response) => {
          console.log("projectService constInfo");
          console.log(response.data);
          // state更新
          setConstInfoState(response.data.objects);
        },
        (error) => {
          setError({ msg: "通信エラー：" + getReqErrorMessage(error), open: true, title: "エラーが発生しました。" })
        }
      );
    }
  }, [searchCondition.constId, handleOpen, setConstInfoState, setError]);

  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        <Typography
          className="Header-constId"
          fontSize={14} // 少しだけ大きく見せる
          color="primary"
        >
          {searchCondition.constId}
        </Typography>
      </Button>
      <Typography className="Header-constName" noWrap>
        {searchCondition.constName}
      </Typography>
      <ConstIdDialog
        open={open}
        canCancel={canCancel}
        onChoise={(project) => {
          handleChoice(project);
        }}
        onClose={() => {
          handleClose();
        }}
        message={message}
      />
    </>
  );
};
export default ConstSelector;
