import React, { useState } from "react";
import {
  //TextField,
  IconButton,
  Dialog,
  //DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import Calendar from "../common/Calendar";

const CalendarDialog = (props) => {
  console.log("CalendarDialog called");

  const {value, onChange} = props;
  const [open, setOpen] = useState(false);
  const [day, setDay] = useState(value);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDay(value);// stateをリセットする
    setOpen(false);
  };

  const handleChange = (changedDay) => {
    setDay(changedDay);
  };

  const handleOk = (event) => {
    console.log("CalendarDialog ok");
    // 親のonChange関数を実行する
    onChange(day);
    // ダイアログを閉じる
    setOpen(false);
  };

  return (
    <>
      <IconButton
        // className="const-icon-button"
        onClick={handleOpen}
        aria-label="CalendarDialog"
        color="primary"
        component="span"
        sx={{padding: '2px'}}
      >
        <DateRangeOutlinedIcon fontSize="medium" />
      </IconButton>
      <Dialog
        //keepMounted // これを有効にするとなぜかWebRTCがブラックスクリーンになってしまう...
        open={open}
        onClose={handleClose}
        //fullScreen
        PaperProps={{
          style: {
            maxHeight: "100%",
          }
         }}
      >
        <DialogContent>
          <Calendar
            value={day}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CalendarDialog;
