import moment from 'moment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const formatDate = (date, format = null) => {
  if(!date) {
    return '';
  }
  if(!format) {
    format = DEFAULT_DATE_FORMAT;
  }
  return moment(date).format(format);
}

const formatDateTime = (date, format = null) => {
  if(!date) {
    return '';
  }
  if(!format) {
    format = DEFAULT_DATE_TIME_FORMAT;
  }
  return moment(date).format(format);
};

// iso8601フォーマットにする
const formatIso = (date, dayOf = null) => {
  if(!date) {
    return '';
  }
  switch(dayOf) {
    case "start":
      // 00:00:00
      return moment(date).startOf("day").toISOString(true);
    case "end":
      // 23:59:59
      return moment(date).endOf("day").toISOString(true);
    default:
      return moment(date).toISOString(true);
  }
}

export {
  formatDate,
  formatDateTime,
  formatIso,
}