import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const ClipboardCopy = ({ label, text }) => {
  console.log("ClipboardCopy callded!");

  const [open, setOpen] = useState(false);

  const message = `${(label !== '') ? label+'を' : ''}クリップボードにコピーしました`;


  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    // クリップボードAPIを利用して、クリップボードに書き込む
    navigator.clipboard.writeText(text);

    setOpen(true);
  };

  return (
    <Tooltip
      arrow
      open={open}
      onClose={handleClose}
      // disableHoverListener
      // disableInteractive
      leaveDelay={1500}
      leaveTouchDelay={1500}
      placement="right-start"
      title={message}
    >
      <div>
        <IconButton
          //edge="end"
          disabled={text === ""}
          onClick={handleClick}
        >
          <ContentCopyOutlinedIcon color="disabled" />
        </IconButton>
      </div>
    </Tooltip>
  );
};
export default React.memo(ClipboardCopy);
