import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';

const CircularProgressWithContent = (props) => {
  return (
    <Box 
      sx={{
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress size={props.size}  />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default CircularProgressWithContent;